import React from 'react'
import Layout from '../hoc/Layout/Layout'
import FAQ from '../components/Pages/FAQ/FAQ'

export default () => {
  return (
    <Layout>
      <FAQ />
    </Layout>
  )
}
