import React, { Component } from 'react'
import { animateScroll as Scroll } from 'react-scroll'
import { FaArrowAltCircleUp } from 'react-icons/fa'
import classes from './ScrollUp.module.css'

class ScrollUp extends Component {
  state = {
    scrolled: false
  }

  scrollToTop = () => Scroll.scrollToTop()

  scrollHandler = () => {
    this.setState({
      scrolled: true
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollHandler)
  }

  // REFACTOR
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  render() {
    return (
      <FaArrowAltCircleUp
        onClick={this.scrollToTop}
        className={this.state.scrolled ? classes.Icon : classes.Hidden}
      />
    )
  }
}

export default ScrollUp
