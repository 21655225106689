import React from 'react'
import { Link } from 'gatsby'
import SEO from '../../seo'
import ScrollUp from '../../UI/ScrollUp/ScrollUp'
import classes from '../PrivacyPolicy/PrivacyPolicy.module.css'
import LockIcon from '../../../images/icons/lock.svg'

export default () => {
  return (
    <div className={classes.PageContainer}>
      <SEO
        title="PMP Preparation | FAQ | PMP Certification"
        keywords={[
          `PMP`,
          `PMP Questions`,
          `PMP Certification`,
          `PMP Exam`,
          `Project Management`
        ]}
        description="Exam Questions to get PMP Certification. Pass Your PMP Exam. Project Manager Skills. FAQ."
      ></SEO>
      <p className={classes.Title}>
        <strong>FREQUENTLY ASKED QUESTIONS</strong>
      </p>
      <p className={classes.SubTitle}>
        <strong>Last updated January 9, 2021</strong>
      </p>
      <br />
      <p className={classes.Heading_1}>
        <strong>TABLE OF CONTENTS</strong>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_1">1. Does the PMP exam change?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_2">2. Are discounts available for any plans?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_3">3. What is your return policy?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_4">4. What is PMP Certification?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_5">5. Where is PMP Certification recognized?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_6">6. Who is eligible to apply for PMP exam?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_7">7. What if you are not eligible for PMP exam?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_8">8. How to apply for PMP exam?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_9">9. How to apply for PMP exam online?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_10">
          10. How long does the PMI review your application?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_11">11. What is the fee for PMP exam?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_12">12. What is the structure of PMP exam?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_13">13. What is the passing score for the PMP exam?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_14">14. What is PMI audit?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_15">15. What if you fail the audit?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_16">16. Do you need to get a PMI membership?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_17">17. Will I have to pay to retake PMP exam?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_18">18. Can 35 contact hours expire?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_19">19. When will you see the PMP exam result?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_20">20. When can I start using the title PMP?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_21">
          21. Is PMBOK® Guide enough to prepare for the PMP exam?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_22">22. Is there a break during the PMP exam?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_23">
          23. Is it possible to reschedule or cancel the PMP exam?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_24">
          24. Is it allowed to use a calculator during your PMP exam?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_25">
          25. Is there a refund if you didn't schedule the exam yet?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_26">26. What is the PDUs?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_27">
          27. What is the difference between PDUs and contact hours?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_28">28. How to earn PDUs?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_29">
          29. Do sample questions in ErudiCAT database get updated?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_30">30. How does PMP Exam Simulator work?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_31">
          31. Can I go back to the questions after I finish the PMP Mock Exam?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_32">
          32. What information is available in statistics after you pass Mock
          Exam?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_33">33. How does performance chart work?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_34">34. What is 'Accelerate Mock Exam' feature?</a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_35">
          35. Can I see the results of all Mock Exams I took?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_36">
          36. Will the result for part of the Mock Exam be available if I don't
          complete it?
        </a>
      </p>
      <p className={classes.TableOfContent}>
        <a href="#answer_37">37. What is 'Lock The Field' feature?</a>
      </p>
      <br />
      <p className={classes.Heading_1}>
        <span id="answer_1" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>1. Does the PMP exam change?</strong>
      </p>
      <p>
        Yes, the exam is updated every 3-4 years. This is due to changes in
        PMBOK® Guide. The next update was supposed to take place on July 1st,
        2020.
        <br />
        But due to COVID-19 outbreak worldwide, PMI made an announcement on
        March 18th., 2020: "For all PMP candidates, we are extending access to
        the current PMP exam until 31 December 2020 and postponing the launch of
        the new PMP exam until 2 January 2021".
        <br />
        More information can be found at{' '}
        <a
          href="https://www.pmi.org/about/coronavirus-single-source-message?_ga=2.2751475.211776938.1584636779-1759259461.1584018552"
          target="_blank"
          rel="noopener noreferrer"
        >
          PMI website
        </a>
        .
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_2" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>2. Are discounts available for any plans?</strong>
      </p>
      <p>
        Yes, we run regular discounts and promotions. For more questions,{' '}
        <Link to="/project-manager-contact">Contact Us</Link>.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_3" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>3. What is your return policy?</strong>
      </p>
      <p>
        The sales are final. You have a free trial prior to your purchase to
        make sure that you are interested in the product of this website.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_4" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>4. What is PMP Certification?</strong>
      </p>
      <p>
        Project Management Professional (PMP) is an internationally recognized
        professional certification offered by the
        <a href="https://www.pmi.org" target="_blank" rel="noopener noreferrer">
          Project Management Institute
        </a>
        (PMI), USA. The PMP certification is an industry standard and is the
        most prestigious in project management. A PMP title is a very respected
        credential and it will be a great boost to your career and will make you
        a more appealing candidate for many positions.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_5" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>5. Where is PMP Certification recognized?</strong>
      </p>
      <p>
        PMP Certification is valid across all industries and recognized
        throughout the world.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_6" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>6. Who is eligible to apply for PMP exam?</strong>
      </p>
      <p>
        PMI has strict eligibility requirements for PMP exam.
        <br />
        <strong>Education Requirement:</strong> You should be either a
        bachelor's degree holder (or global equivalent) or a secondary degree
        holder (high school diploma, associate's degree or global equivalent).
        This can be in any discipline.
        <br />
        <strong>Experience Requirement:</strong> If you are a degree holder, you
        should have at least 4,500 hours (about three years) of experience in
        leading and directing projects, and 7,500 hours (about five years) for a
        diploma holder. Please note, that project management experience should
        be non-overlapping, unique, and earned in the last eight consecutive
        years. Moreover, you should have this experience in all domains of a
        project such as initiating, planning, executing, monitoring and
        controlling, and closing. If you are lacking experience in any process
        group, you will not be able to apply for the PMP certification exam.
        <br />
        <strong>Training Experience:</strong> You must attend 35 contact hours
        of formal project management training. This training can be obtained
        from either a PMI Registered Education Provider (REP) or non-REPs. If
        you are an active CAPM certification holder, your project management
        training requirement is waived.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_7" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>7. What if you are not eligible for PMP exam?</strong>
      </p>
      <p>
        Do not waste your time and money if you know you are not qualified for
        PMP exam. Make sure that you fulfill the eligibility requirements before
        applying for it. If you want to get PMP certification but don't qualify
        for it, you can make yourself eligible with a little effort.
        <br />
        If you are a diploma or a degree holder but you lack project experience,
        you can apply for the CAPM (Certified Associate in Project Management)
        exam. CAPM is a certification for entry-level professionals. This
        certification does not require any project management experience. And
        when you get enough experience, you can then apply for the PMP exam.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_8" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>8. How to apply for PMP exam?</strong>
      </p>
      <p>
        After you made sure you are eligible for application, it's time to
        actually apply for PMP exam. You start with filling out the application
        form. It's not difficult, but time-consuming. You can fill it out online
        or you can print it out and mail it. Most of the candidates fill out
        applications online as it's more convenient and can be sent out
        momentarily. However, you are free to choose which method is better for
        you.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_9" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>9. How to apply for PMP exam online?</strong>
      </p>
      <p>
        The application consists of general questions, questions about project
        management experience and required information about your 35 contact
        hours training program. As this form might take quite some time to fill
        out, you don't have to do it at once. You can save what you've completed
        and continue another day. However, keep in mind, that you must submit it
        within 90 days. Apply on{' '}
        <a
          href="https://www.pmi.org/certifications/process"
          rel="noopener noreferrer"
          target="_blank"
        >
          PMI website
        </a>
        .
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_10" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>10. How long does the PMI review your application?</strong>
      </p>
      <p>
        Once you submit the application for PMP exam, it will take the PMI 5-7
        working days to complete the review.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_11" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>11. What is the fee for PMP exam?</strong>
      </p>
      <p>
        Once you receive the confirmation email from PMI after your application,
        you will have to pay a fee of $555. However, if you are a PMI member you
        will have to pay $405. These prices apply for both center-based exam and
        online exam.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_12" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>12. What is the structure of PMP exam?</strong>
      </p>
      <p className={classes.PwithUL}>
        As of 2 January, 2021 the PMP Certification Exam has multiple changes:
        <br />
        The exam now lasts 230 minutes (compared to 240 minutes before) and
        there are 180 multiple-choice questions (compared to 200 questions
        before). A few thing to keep in mind:
      </p>
      <ul className={classes.UL}>
        <li>
          <p>
            If you don't know the answer, don't leave the question unanswered -
            take a guess.
          </p>
        </li>
        <li>
          <p>
            You roughly have 1.2 minute per question, so don't linger on
            difficult question and plan your time wisely.
          </p>
        </li>
        <li>
          <p>
            Out of 180 questions there are 25 pretest questions that will not
            affect the final score. These pretest questions are used to test the
            validity of future examinations. These questions will be placed
            randomly and you won't know which questions they are. Therefore, you
            should treat all questions seriously as though all of them will
            affect your score.
          </p>
        </li>
        <li>
          <p>
            Before the center-based exam starts you will be shown awareness
            session (15 minutes) which explains how to work with questions. No
            notes are allowed. After the awareness session the exam begins.
            After the exam, there will be a survey. And after that, you will be
            able to see the result. The awareness session and the survey are not
            added to your 230 exam time.
          </p>
        </li>
        <li>
          <p>
            As for the online exam, the "Begin exam" link will be available 30
            minutes before your scheduled exam time. It's recommended to be
            there 30 minutes before in order to make sure you have everything
            ready.
          </p>
        </li>
      </ul>
      <p className={classes.Heading_1}>
        <span id="answer_13" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>13. What is the passing score for the PMP exam?</strong>
      </p>
      <p className={classes.PwithUL}>
        Out of 180 questions there are 25 pretest questions that will not affect
        the final score. Which makes 155 questions scorable. But you should
        still aim to answer all 180 correctly, because it's hard to
        differentiate pretest questions from actual questions that will affect
        your score. The thing is that no one knows the current passing score as
        it's not being revealed by the PMI. The PMI changes the format of the
        exam results periodically.
        <br />
        Here are a few things to know about passing score:
      </p>
      <ul className={classes.UL}>
        <li>
          <p>
            The passing score for the PMP exam is different for every
            professional who takes the test, and it is determined by a
            psychometric analysis. The passing score actually depends on the set
            of questions received.
          </p>
        </li>
        <li>
          <p>
            Not every question carries the same weight. Harder questions are
            worth more than easier questions.
          </p>
        </li>
        <li>
          <p>
            As the percentage of correctly answered questions is unknown, just
            don't worry about the numbers. The best thing you can do is focus
            and answer all of the questions to the best of your knowledge.
          </p>
        </li>
      </ul>
      <p className={classes.Heading_1}>
        <span id="answer_14" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>14. What is PMI audit?</strong>
      </p>
      <p>
        The purpose of the PMI audit is to make sure that right candidates are
        getting the certification. It helps to keep the high standards of the
        certification process. The PMI does not reveal the percentage of
        candidates selected for an audit. And the selection process is random,
        so, all applicants have a chance of being audited.
        <br />
        Auditing verifies the information provided by an applicant. Once you
        submit the application, you will know immediately if you have been
        selected for an audit.
        <br />
        If you are not selected for an audit, you can pay the exam fee and
        proceed to schedule the PMP exam. If you are selected for an audit, the
        PMI will send you an email and instruct you on how to complete the
        process. The PMI will stop your eligibility clock if you are selected
        for the audit, and your eligibility period will start from the day you
        clear it. You will have to pass the PMP exam within one year after this
        date, and you have up to three attempts. In case of being selected for
        audit, you will need to send out proof of education, proof of training
        and proof of experience.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_15" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>15. What if you fail the audit?</strong>
      </p>
      <p className={classes.PwithUL}>
        If you fail the audit, you may or may not get a suspension period. There
        is a chance of a lifetime ban from the PMP exam if you provide false
        information intentionally.
        <br />
        You can fail the audit for various reasons:
      </p>
      <ul className={classes.UL}>
        <li>
          <p>
            Non-Compliance - if you decided not to proceed with the audit, you
            will be banned from applying for the PMP exam for one year.
          </p>
        </li>
        <li>
          <p>
            No fault - If for some reason, your education or experience cannot
            be confirmed, through no fault of your own (ex. your supervisor is
            not able to verify your experience at the moment), then PMI will
            still let you fail the audit. You may reapply as soon as you have
            your experience hours documented or confirmation of your degree.
          </p>
        </li>
        <li>
          <p>
            Fraud - if you failed the audit because you provided the wrong
            information, the PMI will ban you for life, and you will not be able
            to apply for the exam ever again. Being truthful and honest with the
            PMI is the only way to pass the audit.
          </p>
        </li>
      </ul>
      <p className={classes.Heading_1}>
        <span id="answer_16" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>16. Do you need to get a PMI membership?</strong>
      </p>
      <p className={classes.PwithUL}>
        It's not mandatory to become a PMI member. You can apply for PMP exam
        without having a PMI membership. However, you might want to consider it
        because it saves some money. The cost of a PMI membership is $139. The
        cost of the PMP exam for non-PMI members is $555. The cost of the PMP
        exam for PMI members is $405. So even with the membership fee, you save
        $11 in the end. Plus, a PMI membership gives free access to a digital
        copy of the PMBOK® Guide. If you are preparing for the PMP exam, you
        will have to study the PMBOK® Guide, and it costs $60 for a printed
        version.
        <br />
        There is a list of other benefits for PMI members:
      </p>
      <ul className={classes.UL}>
        <li>
          <p>
            Ability to download templates, tools, and checklists from the PMI
            website and use them for your projects.
          </p>
        </li>
        <li>
          <p>
            The PMI has a vast collection of free webinars, and you can earn
            unlimited numbers of Category-A PDUs by watching these webinars.
          </p>
        </li>
        <li>
          <p>
            Networking is another benefit of a PMI membership. There are
            opportunities to connect with professionals through Communities of
            Practice or in PMI chapter meetings. You can learn a great deal by
            connecting with professionals.
          </p>
        </li>
        <li>
          <p>
            You're also entitled to get a copy of the Project Management
            Journal, which advances the understanding of project, program and
            portfolio management, and is published five times a year.
          </p>
        </li>
        <li>
          <p>
            You are entitled to receive free monthly copies of the PM Network
            and PMI Today Magazines published by the PMI.
          </p>
        </li>
        <li>
          <p>Access to various research papers.</p>
        </li>
      </ul>
      <p className={classes.Heading_1}>
        <span id="answer_17" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>17. Will I have to pay to retake PMP exam?</strong>
      </p>
      <p>
        There is a discount for second and third attempt to pass the exam. For
        the center-based testing and online testing PMI members will pay $275
        and non-members will pay $375.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_18" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>18. Can 35 contact hours expire?</strong>
      </p>
      <p>
        No. 35 contact hours that you've earned will never going to expire.
        Don't worry if you earned them years back - you can apply for the PMP
        exam.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_19" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>19. When will you see the PMP exam result?</strong>
      </p>
      <p>
        You will receive your exam results immediately after completing the test
        and you will be given a printout of your result report.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_20" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>20. When can I start using the title PMP?</strong>
      </p>
      <p>
        You can start using the title "PMP" immediately after passing the exam.
        You will receive an email from the PMI within 1-2 weeks, and afterwards,
        you will be able to see your certification information on the PMI
        registry and in your account. You will receive your physical certificate
        within 4-6 weeks.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_21" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>21. Is PMBOK® Guide enough to prepare for the PMP exam?</strong>
      </p>
      <p>
        The PMP exam is based on the PMBOK® Guide and it's a must-read book.
        However, it's not enough as it does not address all the questions on the
        test. Therefore, don't limit yourself to the PMBOK® Guide only, but read
        several books. In addition to books, you must practice multiple exam
        sample questions. Also, it's highly recommended that you use{' '}
        <Link to="/">PMP Exam Simulator</Link> which is made to simulate the
        actual exam. It runs at the same pace as the actual exam and can give a
        clear understanding of whether you stand a chance to pass PMP exam.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_22" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>22. Is there a break during the PMP exam?</strong>
      </p>
      <p>
        According to the changes in effect as of 2 January, 2021 there are no
        scheduled breaks for paper-based exams. However, there will be one
        additional break for a total of two 10-minute breaks for computer-based
        tests.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_23" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>
          23. Is it possible to reschedule or cancel the PMP exam?
        </strong>
      </p>
      <p>
        Yes. If you give more than 30 days' notice, you can reschedule/cancel
        your exam for free. It will cost you $70 to change your exam date or
        cancel the exam 30 days before your scheduled time, but doing so within
        two days of the exam will forfeit your full exam fee, there is no refund
        in this case.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_24" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>
          24. Is it allowed to use a calculator during your PMP exam?
        </strong>
      </p>
      <p>
        The PMP exam has formula-based questions that involve mathematical
        calculations. You are allowed to use a calculator during your PMP
        certification exam. You can either use the built-in calculator
        application through the computer or ask a staff member to provide you
        with a calculator. You cannot take your own calculator into the
        examination room. You have to leave your personal items in a locker
        before entering. You will only be allowed to keep the key to your
        locker.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_25" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>
          25. Is there a refund if you didn't schedule the exam yet?
        </strong>
      </p>
      <p className={classes.PwithUL}>
        If you have not yet scheduled the exam and you have over 30 days until
        your PMP eligibility expires, you can get your money back. However, the
        PMI will deduct $100 fee. You will get your money back with a deduction
        of $100 if you failed the audit.
        <br />
        You will not get your money back in the following cases:
      </p>
      <ul>
        <li>
          <p>
            Your one-year eligibility period has expired and you have not
            scheduled the exam.
          </p>
        </li>
        <li>
          <p>
            You scheduled the exam but did not show, and you were unable to
            provide a proper reason to cancel or reschedule.
          </p>
        </li>
        <li>
          <p>You failed the PMP exam.</p>
        </li>
        <li>
          <p>
            If you have scheduled the exam, a different rule applies, check{' '}
            <a href="#answer_23">FAQ 23</a>.
          </p>
        </li>
      </ul>
      <p className={classes.Heading_1}>
        <span id="answer_26" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>26. What is the PDUs?</strong>
      </p>
      <p>
        The PMP certification is valid for three years. PDUs or Professional
        Development Units are required to renew your PMP certificate every 3
        years. Simply speaking, it is a unit used to measure ongoing development
        within the field of project management. In order to maintain
        certification as a Project Management Professional (PMP), you will need
        to maintain a specific number of PDUs, which can be earned by attending
        events or accomplishing courses.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_27" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>
          27. What is the difference between PDUs and contact hours?
        </strong>
      </p>
      <p>
        You need 35 contact hours to apply for the PMP exam. This is one of the
        three mandatory eligibility requirements to apply for the PMP exam.
        <br />
        PDUs are needed when you become a PMP, and your certificate is about to
        expire after three years. You can complete the CCR program and renew
        your PMP certificate for three more years with the help of these PDUs.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_28" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>28. How to earn PDUs?</strong>
      </p>
      <p>
        There are two ways to earn PDUs. It's ongoing education and giving back
        to the industry.
        <br />
        <strong>Ongoing education:</strong> take in-person or online courses,
        attend seminars, webinars, industry events or get into self-directed
        learning and take it at your own pace. Just make sure the course you're
        taking is officially sanctioned to give out PDUs.
        <br />
        <strong>Giving back to the industry:</strong> create content that can be
        added to the body of knowledge, volunteer to provide your project
        management services outside of your company, or simply do your job
        (practicing project management daily counts toward your credentials).
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_29" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>
          29. Do sample questions in ErudiCAT database get updated?
        </strong>
      </p>
      <p>
        Yes, we monitor all trends and changes in the industry and update our
        database of PMP sample questions regularly.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_30" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>30. How does PMP Exam Simulator work?</strong>
      </p>
      <p>
        PMP Exam Simulator is designed to simulate the actual exam. As of 2
        January, 2021 the exam lasts 230 minutes and has 180 questions. We give
        additional duration options with corresponding amount of questions only
        for training purposes.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_31" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>
          31. Can I go back to the questions after I finish the PMP Mock Exam?
        </strong>
      </p>
      <p>
        Yes, once you finish Mock Exam, you are provided with statistics and
        performance chart. Questions statistics has a list of all questions you
        had to answer during the exam. The list displays whether your answer was
        correct or not. You are able to look through every question and
        understand why you made mistakes.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_32" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>
          32. What information is available in statistics after you pass Mock
          Exam?
        </strong>
      </p>
      <p>
        Once you finish Mock Exam, you are provided with fields and questions
        statistics.
        <br />
        Fields statistics displays your level of understanding a particular
        field of project management. This level depends on the amount of
        questions you answered correctly. It's a great way to see whether you
        need to fill a gap in a certain area of project management.
        <br />
        Questions statistics has a list of all questions you had to answer
        during the exam. The list displays whether the answer was correct or
        not. You are able to look through every question and understand why you
        made mistakes.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_33" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>33. How does performance chart work?</strong>
      </p>
      <p>
        PMP Exam Simulator processes your Mock Exam results and generates
        performance chart. Your success is measured in correspondence with the
        following levels: 'Needs Improvement', 'Below Target', 'Target' and
        'Above Target'. Your aim is to have 'Target' or 'Above Target' result.
        Do not rely on only one good result, anyone can get lucky. Make sure
        that you have several consecutive positive Mock Exam results before you
        decide to sit for the actual exam.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_34" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>34. What is 'Accelerate Mock Exam' feature?</strong>
      </p>
      <p>
        Since there is no break during the actual PMP exam, it's highly
        recommended that you train to answer the questions as fast as possible.
        This strategy will help you to have some extra time left during the exam
        that you can use for a short break or to review some answers that you
        have doubts about. This feature is created for training purposes only.
        In other words, it's an option to make your Mock Exam even harder in
        order to ensure you will have less stress during the actual exam.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_35" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>35. Can I see the results of all Mock Exams I took?</strong>
      </p>
      <p>
        Yes, the result of each Mock Exam you take will be saved in you account.
        Once you <Link to="/pmp-training-sign-in">sign in</Link> to your
        account, you can see the history of all Mock Exams you took.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_36" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>
          36. Will the result for part of the Mock Exam be available if I don't
          complete it?
        </strong>
      </p>
      <p>
        No, in order for the result to be generated, you have to complete Mock
        Exam either by answering all the questions or running out of time. If
        you stop halfway for some reason, the result will not be generated.
      </p>
      <p className={classes.Heading_1}>
        <span id="answer_37" className={classes.Anchor}>
          {' '}
          &nbsp;{' '}
        </span>
        <strong>37. What is 'Lock The Field' feature?</strong>
      </p>
      <p>
        <span>
          All of the sample questions in this database are random and the answer
          options are constantly shuffled. There is an option to{' '}
          <img src={LockIcon} className={classes.Icon} alt="lock icon" /> lock
          the field in case you have a gap in a particular knowledge field of
          project management and want to practice answering questions from this
          field only.
        </span>
      </p>
      <ScrollUp />
    </div>
  )
}
